import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    name: "/",
    path: "/",
    component: () => import("../views/index.vue"),
    meta: { title: "设备信息" },
  },
  {
    name: "home",
    path: "/home",
    component: () => import("../views/home.vue"),
    meta: { title: "提报故障" },
  },
];
const router = createRouter({
  history: createWebHistory(), //路由模式
  routes,
});

export default router;
