<template>
  <router-view v-wechat-title="route.meta.title"></router-view>
</template>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
  margin: 0px;
}
.btn-use {
  width: 335px;
  height: 48px;
  background-color: #257efa;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 5px;
  color: #ffffff;
  border-radius: 24px;
  margin: 20px auto 0;
}

.toast_ {
  position: absolute;
  top: 88px;
  width: 56px;
  margin-left: -28px;
  left: 50%;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #257efa;
}
.del {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.icon {
  position: absolute;
  top: 32px;
  left: 50%;
  margin-left: -18px;
  width: 36px;
  height: 36px;
}
.toast_title {
  position: absolute;
  width: 282px;
  height: 128px;
  left: 50%;
  margin-left: -141px;
  top: 250px;

  background: #ffffff;
  border-radius: 14px;
}
.bgc_zhezhao {
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow-y: no;
}

.btn-err {
  width: 335px;
  height: 48px;
  background-color: #adc8ff;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 5px;
  color: #ffffff;
  border-radius: 24px;
  margin: 15px auto 70px;
}
.title {
  height: 40px;
  background: linear-gradient(
    89.95deg,
    rgba(226, 140, 129, 0.15) -0.76%,
    rgba(226, 140, 129, 0) 99.16%
  );
  border-radius: 0px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  line-height: 40px;
  padding-left: 16px;
  color: #e98d78;
}
.ful {
  margin: 0 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
}
.left {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 48px;
  color: #3b3d3d;
}
.right {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  color: #b6bdbd;
}
:-moz-placeholder {
  font-family: "PingFang SC";
  font-style: normal;
  font-size: 14px;
  text-align: right;
  color: #b6bdbd;
}

::-moz-placeholder {
  font-family: "PingFang SC";
  font-style: normal;
  font-size: 14px;
  text-align: right;
  color: #b6bdbd;
}

::-webkit-input-placeholder {
  font-family: "PingFang SC";
  font-style: normal;
  font-size: 14px;
  text-align: right;
  color: #b6bdbd;
}

:-ms-input-placeholder {
  font-family: "PingFang SC";
  font-style: normal;
  font-size: 14px;
  text-align: right;
  color: #b6bdbd;
}
.right_input {
  outline: none;
  border: none;
}
.yellow_title {
  color: #ffcc00 !important;
  background: linear-gradient(
    90.1deg,
    rgba(255, 171, 0, 0.1) -1.07%,
    rgba(255, 255, 255, 0) 99.92%
  ) !important;
}
.tro {
  margin-top: 30px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  color: #b6bdbd;
}
.del_img {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  top: -8px;
  left: -8px;
  border-radius: 50%;
}
.del_box {
  position: relative;
}
.img_box {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.ok_img {
  width: 80px;
  height: 80px;
  margin-right: 8px;
  border-radius: 2px;
}
.show_img {
  display: flex;
  left: 16px;
  top: 710px;
}
.input_img {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}
.img_border {
  top: 50%;
  left: 50%;
  margin-left: -13.5px;
  margin-top: -12.5px;
  position: absolute;
  padding: 1px;
  border: 1px dashed #979797;
}
.addImg {
  position: relative;
  width: 80px;
  height: 80px;
  background: #f5f6f8;
  border-radius: 2px;
}
.img_icon {
  width: 25px;
  height: 23px;
}
.question_title {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  color: #969699;
}
.question_input {
  outline: none;
  width: 100%;
  height: 32px;
  border: none;
  margin-top: 20px;
  border-bottom: 1px solid #f5f6f8;
}
.question_box {
  padding: 16px;
}

.blue_title {
  background: linear-gradient(
    90.16deg,
    rgba(37, 126, 250, 0.1) -1.01%,
    rgba(255, 255, 255, 0) 99.89%
  ) !important;
  color: #6aa6fa !important;
}
</style>
