import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import axios from "axios";
import vueWechatTitle from "vue-wechat-title";
const baseUrl = "https://ylsb.westts.cn/api";
const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.baseUrl = baseUrl;
app.use(vueWechatTitle).use(router).use(store).mount("#app");

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
